import { ElementType, forwardRef } from 'react'
import { default as MuiBox, BoxProps as MuiBoxProps } from '@mui/system/Box'
import { OverrideProps } from '../../types/mui.types'

interface BoxTypeMap<P = Record<string, unknown>, D extends ElementType = 'div'> {
  props: Omit<P, 'style'> & Pick<MuiBoxProps, 'children' | 'id' | 'key' | 'ref' | 'sx'>
  defaultComponent: D
}

export type BoxProps<
  D extends ElementType = BoxTypeMap['defaultComponent'],
  P = { component?: ElementType }
> = OverrideProps<BoxTypeMap<P, D>, D>

/**
 * The Box component serves as a wrapper component for most of the CSS utility needs.
 *
 * More info can be found at: https://mui.com/material-ui/react-box/
 */

export const Box = forwardRef<HTMLDivElement, BoxProps>((props, ref): JSX.Element => {
  return <MuiBox {...props} ref={ref} />
})

Box.displayName = 'Box'
