import { ButtonProps } from '../Button/Button'
import { IconComponent } from '../../types/util'
import { forwardRef } from 'react'
import { useTheme } from '@mui/material/styles'
import MuiButton from '@mui/material/Button'

export type IconButtonProps = Omit<
  ButtonProps,
  'startIcon' | 'endIcon' | 'children' | 'fullWidth' | 'sx'
> & {
  icon: IconComponent
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ icon: Icon, size = 'medium', ...props }, ref) => {
    const theme = useTheme()

    const width = {
      small: theme.spacing(8),
      medium: theme.spacing(10),
      large: theme.spacing(12)
    }[size]

    const padding = {
      small: theme.spacing(1.5),
      medium: theme.spacing(2),
      large: theme.spacing(3)
    }[size]

    const iconSize = {
      small: theme.spacing(5),
      medium: theme.spacing(6),
      large: theme.spacing(6)
    }

    return (
      <MuiButton
        ref={ref}
        size={size}
        {...props}
        sx={{
          borderRadius: '50%',
          minWidth: 'unset',
          width,
          height: width,
          padding,
          '.MuiBox-root': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          },
          '.fc-icon-button': {
            width: iconSize[size],
            height: iconSize[size]
          }
        }}
      >
        <Icon className="fc-icon-button" />
      </MuiButton>
    )
  }
)

IconButton.displayName = 'IconButton'
