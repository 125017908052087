import {
  ColorProps,
  PositionProps,
  color,
  flexbox,
  layout,
  position,
  space,
  system,
  typography,
  variant
} from 'styled-system'
import { CombinedTextProps, HTMLType, TextProps, TextStyleProps } from '../../../types'
import { FC, ReactNode } from 'react'
import { HTMLMotionProps, m as motion } from 'framer-motion'
import { TEXT_VARIANTS } from '../../../constants'
import styled from 'styled-components'

const textTransform = system({
  textTransform: {
    property: 'textTransform'
  }
})

const textDecoration = system({
  textDecoration: {
    property: 'textDecoration'
  }
})

const wordBreak = system({
  wordBreak: {
    property: 'wordBreak'
  }
})

const TextBase = styled.div<any>`
  margin: 0;
  font-family: ${props => props.theme.fontFamily};
  cursor: ${(props: TextProps) => props.cursor || 'inherit'};
  ${variant({
    variants: TEXT_VARIANTS
  })}
  ${typography}
  ${color}
  ${space}
  ${layout}
  ${textTransform}
  ${flexbox}
  ${textDecoration}
  ${wordBreak}
`

/**
 * Gets the element for the given variant, whether `variant` is a string, an array, or an object.
 * @note exported for testing purposes
 */
export const getVariantElem = (variant: CombinedTextProps['variant']): HTMLType => {
  if (typeof variant === 'string') {
    return TEXT_VARIANTS[variant]?.element
  } else {
    const index = Array.isArray(variant) ? variant[0] : variant && Object.values(variant)[0]
    return index ? TEXT_VARIANTS[index]?.element : 'p'
  }
}

export const Text: FC<CombinedTextProps> = ({
  children,
  component,
  variant = 'body/small',
  className,
  ...rest
}: TextProps & TextStyleProps) => {
  const elem: HTMLType = getVariantElem(variant)
  return (
    <TextBase as={component || elem} variant={variant} className={className} {...rest}>
      {children}
    </TextBase>
  )
}

const TruncateTextBase = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ maxWidth }) => !maxWidth && `max-width: 250px;`}
  white-space: nowrap;
  ${({ m }) => m && `margin: ${String(m)};`}
`
type ToolTipProps = HTMLMotionProps<'span'> & PositionProps & ColorProps
const ToolTip = styled(motion.span)<ToolTipProps>`
  color: black;
  background-color: white;

  ${position}
  ${color}
`
export const TruncateTextContainer = styled(motion.div)`
  position: relative;

  ${ToolTip} {
    position: absolute;
    z-index: 1;

    width: max-content;
    max-width: 500px;
    transform: translate(15px, -70%);

    margin: 0;
    padding: 5px;
    border: 1px solid #dedfe0;
    border-radius: 6px;

    pointer-events: none;
  }
`

const textAnimation = {
  rest: {
    display: 'none',
    opacity: 0,
    transition: {
      duration: 0.2,
      type: 'tween',
      ease: 'easeIn'
    }
  },
  hover: {
    display: 'unset',
    opacity: 1
  }
}

interface TruncateTextProps {
  children?: ReactNode
  tooltip?: string
  tooltipProps?: ToolTipProps
  suppressTooltip?: boolean
}

export const TruncateText: FC<TruncateTextProps & CombinedTextProps> = ({
  children,
  tooltip,
  tooltipProps,
  suppressTooltip,
  ...rest
}: TruncateTextProps & Omit<CombinedTextProps, 'children'>) => {
  return tooltip && !suppressTooltip ? (
    <TruncateTextContainer initial="rest" whileHover="hover" animate="rest">
      <ToolTip data-testid="tooltip-text" variants={textAnimation} {...tooltipProps}>
        <Text variant="body/small" color="inherit" fontWeight="bold">
          {tooltip}
        </Text>
      </ToolTip>
      <TruncateTextBase data-testid="truncated-text" {...rest}>
        {children}
      </TruncateTextBase>
    </TruncateTextContainer>
  ) : (
    <TruncateTextBase data-testid="truncated-text" {...rest}>
      {children}
    </TruncateTextBase>
  )
}

/* Structured so component names mirror Figma names for variants

  ex. Figma Name - "body/large" maps to

  (importing)
  import { BodyText } from '...'

  (usage)
  <BodyText.Large/>
*/

type TextVariantCollection<K extends string> = Record<K, FC<CombinedTextProps>>

// each of the following maps to an approved typography value - https://zeroheight.com/12450b6dd/p/121f33-typography/b/91fb39

export const BodyText: TextVariantCollection<'Large' | 'Medium' | 'Small'> = {
  Large: function C(props) {
    return (
      <Text variant="body/large" {...props}>
        {props.children}
      </Text>
    )
  },
  Medium: function C(props) {
    return (
      <Text variant="body/medium" {...props}>
        {props.children}
      </Text>
    )
  },
  Small: function C(props) {
    return (
      <Text variant="body/small" {...props}>
        {props.children}
      </Text>
    )
  }
}

export const GeneralText: TextVariantCollection<'CaptionAndLabel'> = {
  CaptionAndLabel: function C(props) {
    return (
      <Text variant="general/captionAndLabel" {...props}>
        {props.children}
      </Text>
    )
  }
}

export const DisplayText: TextVariantCollection<'XLarge' | 'Large' | 'Medium' | 'Small'> = {
  XLarge: function C(props) {
    return (
      <Text variant="display/xLarge" {...props}>
        {props.children}
      </Text>
    )
  },
  Large: function C(props) {
    return (
      <Text variant="display/large" {...props}>
        {props.children}
      </Text>
    )
  },
  Medium: function C(props) {
    return (
      <Text variant="display/medium" {...props}>
        {props.children}
      </Text>
    )
  },
  Small: function C(props) {
    return (
      <Text variant="display/small" {...props}>
        {props.children}
      </Text>
    )
  }
}

// each of the following maps to a component-defined typography value - https://zeroheight.com/12450b6dd/p/121f33-typography/b/03f295

export const AccordionText: TextVariantCollection<'Title' | 'Secondary' | 'Context' | 'Link'> = {
  Title: BodyText.Medium,
  Secondary: BodyText.Medium,
  Context: BodyText.Small,
  Link: function C(props) {
    return (
      <BodyText.Small textDecoration="underline" {...props}>
        {props.children}
      </BodyText.Small>
    )
  }
}

export const BannerText: TextVariantCollection<'Mobile' | 'Desktop'> = {
  Mobile: function C(props) {
    return (
      <GeneralText.CaptionAndLabel fontWeight={700} {...props}>
        {props.children}
      </GeneralText.CaptionAndLabel>
    )
  },
  Desktop: function C(props) {
    return (
      <BodyText.Small fontWeight={700} {...props}>
        {props.children}
      </BodyText.Small>
    )
  }
}

export const ButtonText: TextVariantCollection<'General' | 'PageNav' | 'Link'> = {
  General: function C(props) {
    return (
      <BodyText.Medium fontWeight={700} {...props}>
        {props.children}
      </BodyText.Medium>
    )
  },
  PageNav: function C(props) {
    return (
      <BodyText.Small fontWeight={700} {...props}>
        {props.children}
      </BodyText.Small>
    )
  },
  Link: function C(props) {
    return (
      <BodyText.Small textDecoration="underline" {...props}>
        {props.children}
      </BodyText.Small>
    )
  }
}

export const ChartText: TextVariantCollection<'Title' | 'Label' | 'Message'> = {
  Title: BodyText.Medium,
  Label: BodyText.Small,
  Message: BodyText.Medium
}

export const CheckboxText: TextVariantCollection<'Regular' | 'Small'> = {
  Regular: BodyText.Medium,
  Small: GeneralText.CaptionAndLabel
}

export const HeaderText: TextVariantCollection<'Page' | 'Section'> = {
  Page: BodyText.Large,
  Section: BodyText.Small
}

export const InputText: TextVariantCollection<'Label' | 'Helper' | 'Field'> = {
  Label: GeneralText.CaptionAndLabel,
  Helper: GeneralText.CaptionAndLabel,
  Field: BodyText.Medium
}

export const NavigationText: TextVariantCollection<
  'Header' | 'Footer' | 'Trademark' | 'TabActive' | 'TabInactive'
> = {
  Header: BodyText.Small,
  Footer: BodyText.Small,
  Trademark: GeneralText.CaptionAndLabel,
  TabActive: function C(props) {
    return (
      <BodyText.Small fontWeight={700} {...props}>
        {props.children}
      </BodyText.Small>
    )
  },
  TabInactive: BodyText.Small
}

export const PillText: TextVariantCollection<'Active' | 'Inactive'> = {
  Active: function C(props) {
    return <BodyText.Medium fontWeight={700}>{props.children}</BodyText.Medium>
  },
  Inactive: BodyText.Medium
}

export const RadioText: TextVariantCollection<'Regular'> = {
  Regular: BodyText.Medium
}

export const TableText: TextVariantCollection<'Label' | 'Entry'> = {
  Label: GeneralText.CaptionAndLabel,
  Entry: BodyText.Small
}

export const TagText: TextVariantCollection<'Label'> = {
  Label: GeneralText.CaptionAndLabel
}

export const ToastText: TextVariantCollection<'Content'> = {
  Content: BodyText.Medium
}

export const ToggleText: TextVariantCollection<'Label' | 'Input' | 'PageNav'> = {
  Label: GeneralText.CaptionAndLabel,
  Input: BodyText.Small,
  PageNav: function C(props) {
    return (
      <BodyText.Small fontWeight={700} {...props}>
        {props.children}
      </BodyText.Small>
    )
  }
}
export const TextGradient = styled(Text)<{ direction: string; colors: string[] }>`
  display: inline-block;
  background-color: black; // browser support fallback
  background-image: linear-gradient(
    ${({ direction, colors }) => `${direction}, ${colors.join(',')}`}
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
`
